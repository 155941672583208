<template>
  <v-tooltip
    :top="!is_mobile"
    :right="is_mobile && order % 2 === 1"
    :left="is_mobile && order % 2 === 0"
    content-class="cs-hover-bg"
    open-on-click
    :open-on-focus="false"
    :open-on-hover="!is_mobile"
    :open-delay="300"
    :close-delay="150"
    :nudge-top="is_mobile ? 40 : -10"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card
          v-bind="attrs"
          v-on="on"
          :elevation="hover ? 1 : 0"
          flat
          :class="['fill-height', 'card-base', {'card-hover': hover}]"
          :width="size"
        >
          <v-card-text class="d-flex fill-height flex-column align-center pa-0">
            <v-img
              :width="size"
              :max-height="size"
              :src="`${item.avatar}?5`"
              class="mb-3 rounded"
            />
            <div class="text-h6 text-center grey--text text--darken-4 font-weight-medium">{{ item.name }}</div>
            <div class="text-subtitle-2 text-center grey--text text--darken-2">{{ item.career }}</div>
          </v-card-text>
        </v-card>
      </v-hover>
    </template>
    <div class="grey--text text--darken-2 pa-5 ma-0" :style="detailWidth">
      <img :src="item.avatar" :style="styleObj" class="mr-3 mb-0 d-inline" />
      <span class="text-h6 font-weight-bold grey--text text--darken-4">{{item.name}}</span><br>{{ item.intro ? item.intro : item.career }}
    </div>
  </v-tooltip>
</template>
<script>
import Tools from '@/mixins/Tools.js'
export default {
  name: 'LecturerCard',
  mixins: [Tools],
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 166
    }
  },
  computed: {
    styleObj() {
      return {
        width: `166px`,
        height: `166px`, // `${this.size}px`,
        float: 'left'
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.card-base
  transition: all .2s
.card-hover
  transform: translateY(-7px)
  box-shadow: 0 26px 40px -24px rgba(147, 157, 178, 0.3)
.cs-hover-bg
  background: #FFFFFF
  border: 1px solid #ECECEC
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.15)
  padding: 0
  margin: 0
  opacity: 0.99
  point-events: painted
</style>
